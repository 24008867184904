<template lang="pug">
div
  ResultsMultiple(:key="0" :serviceNumber="serviceNumber" :hasError="hasError" v-model:invoices="invoices_to_paid" :service="service" :showOption="false")
</template>
<script>
import { onMounted, ref } from "vue";
import { store } from "@/store";
import { axios } from "@/utils/axios";
import { useRoute } from "vue-router";
import ResultsMultiple from "@/views/Guest/QuickPayment/ResultsMultiple.vue";
const loading = ref(false);
export default {
  components: { ResultsMultiple },
  setup() {
    const id = ref(0);
    const serviceNumber = ref("");
    const service = ref({});
    const invoices = ref([]);
    const route = useRoute();
    const hasError = ref(false);
    const invoices_to_paid = ref([]);
    // const router = useRouter();

    const loadInvoices = async (service_name) => {
      try {
        store.APP_LOADING = true;
        const { data } = await axios.get(`service/quick/${service_name}`);
        console.log(data);
        invoices.value = data.payload;
        invoices_to_paid.value = invoices.value
          .filter((invoice) => invoice.payment_status == false)
          .map((invoice) => ({
            ...invoice,
            state_to_pay: true,
          }));
        service.value = data.service[0];
      } catch (error) {
        console.log(error);
      } finally {
        console.log("finally");
      }
    };

    onMounted(async () => {
      console.log("onMounted [QuickPayment]");
      try {
        id.value = route.params.id;
        serviceNumber.value = route.params.service_id;
        store.APP_LOADING = true;
        loading.value = true;
        await loadInvoices(serviceNumber.value);
      } catch (error) {
        console.log(error);
      } finally {
        store.APP_LOADING = false;
        loading.value = false;
      }
    });
    return {
      loading,
      id,
      serviceNumber,
      service,
      invoices,
      hasError,
      invoices_to_paid,
    };
  },
};
</script>
